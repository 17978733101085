<template>
  <main>
  <div class="contenu">


  <div style="position:relative;">
     <!-- mobile -->
     <div class="logo mobile"><router-link to="/"><img  src="../assets/images/logo.png"></router-link></div>
     <img class="top-mobile mobile" src="../assets/images/top-vague4-mobile.jpg">
     <!-- commun -->
     <div class="top-contact"><img src="../assets/images/top-contact.png"></div>
  </div>
    <div class="contact">
      <p>
        Pour toute question, adressez votre <br />demande par e-mail au plus tard<br /> 
        le 30/06/2021 à l’adresse<br /> <a href="mailto:contact@playdoh65.fr">contact@playdoh65.fr</a>
        <br /><br />
        N’hésitez pas à joindre à votre demande <br />toutes pièces nécessaires à l’étude de<br /> votre dossier.
      </p>
    </div>
    </div>
    <img class="mobile" src="../assets/images/bottom-contact.jpg" width="100%">
  </main>
</template>
<script>
export default {
  name: 'Contact',
}
</script>
<style>


</style>